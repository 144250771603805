const languaje = {
  header: {
    spa: "SPA",
    eng: "ENG",
    home: "Home",
    tools: "Technological tools",
    products: "Products and services",
    coverage: "Full coverage (insurance)",
    contact: "Contact us",
  },
  home: {
    carouselText1: "AI risk prediction",
    carouselText2: "Technology for transport",
    carouselText3: "Technology for logistics",
    carouselText4: "Leader in security",
    carouselText5: "Leader in prevention",
    icon1Text: "Years of experience",
    icon2Text: "Transport lines",
    icon3Text: "AAA clients",
  },
  tools: {
    title1: "Technological",
    title2: "tools",
    subtitle: `We develop technological tools supported by AI, 
    to integrate into any operation or system.`,
    cart1Title: "ELIAS",
    cart1Subtitle: `Digital twin created with natural language processing 
		technology for fleet monitoring and communication with operators.`,
    cart1SubtitleMobile: `Gemelo digital creado con tecnología de procesamiento  
    para monitoreo de flotas y comunicación con operadores.`,
    cart2Title: "Risk algorithm",
    cart2Subtitle: `First predictive algorithm on the market, capable of 
    anticipating and managing logistical risks before, during and after setting
     out on the road. `,
    cart2SubtitleMobile: `Primer algoritmo predictivo del mercado, capaz de 
    anticipar y gestionar riesgos logísticos antes, `,
    cart3Title: "Follow/Protector",
    cart3Subtitle: `Mobile application that provides real-time location information of shipments, 24/7 visibility of operations and panic button included`,
    cart3SubtitleMobile: `Mobile application that provides real-time location information of shipments, 24/7 visibility of operations and panic button included`,
    cart4Title: "RPA ",
    cart4Subtitle: `We develop our own AI-trained RPA that allows us to connect 
    to any monitoring system.`,
  },
  products: {
    title1: "Products",
    title2: "and services",
    subtitle1: "What are our products and services?",
    subtitle2: "",
    cart1Title1: "AiS Secure",
    cart1Title2: "Mobility",
    cart1Button: "Login >",
    cart1Content1: `Learn our AIS Secure Mobility technological platform 
		and obtain full control of your shipments, real-time risk analysis, 
		recommendations to reduce the risk percentage and reports at the end of 
		each trip.`,
    cart1Content2: `We alert you to the most relevant events and take 
		automatic action to protect you`,
    cart1Content3: `Easy implementation system for any operation thanks to our 
		RPA technology.`,
    cart1Content4: `Buy protection according to the volume of your trips in 
		real time.`,
    cart1Button1: "Protect your operation",
    cart1Button2: "Buy here",
    cart2Title: "AIN",
    cart2Content1: `Intelligent monitoring for fleets in real time 24/7, 
    offers total visibility through a Power BI Dashboard and risk control 
    for your fleets with Artificial Intelligence.`,
    cart2Content2: `The system is easy to implement and integrates with your 
		current GPS, designed for both heavy and light fleets.`,
    cart2Content3: `We provide real-time alerts about risk, speed, driver 
		fatigue, or distraction.`,
    cart2Button1: "Protect your fleet",
    cart2Button2: "Contact here",
  },
  coverage: {
    title1: "Our",
    title2: " operations",
    cart1Title: "Coverage capacity:",
    cart1Content: `We have national and international coverage capacity 
    throughout the entire continent of America.`,
    cart2Title: "Reduction in accidents:",
    cart2Content: `Thanks to our AI, only 0.04% of our clients' operations 
    suffer losses.`,
    cart3Title: "Predictive capacity:",
    cart3Content: `We monitor 900 thousand trips monthly. 2 billion data 
		collected per month, to achieve 704 thousand daily predictions.`,
    cart4Title: "Operational capacity:",
    cart4Content: `We have protected 12 thousand transportation lines 
    and 47 billion pesos in assets and merchandise each year.`,
  },
  customers: {
    cart1Title1: "Full",
    cart1Title2: " coverage",
    cart1Content: `AI27 can help you obtain better conditions on your fleet or 
		cargo insurance policy through our partners. Contact your agent or broker 
		to request coverage with AI27.`,
    cart1Button1: "Contact experts",
    cart1Button2: "Contact here",
    cart2Title1: "Our",
    cart2Title2: " clients",
    cart2Content: `Our clients trust in the technological solutions and products
     that we have developed, which has allowed us to build a solid reputation 
     in key sectors such as grocery, pharmaceutical, retail, technology, 
     among others.`,
  },
  contact: {
    formTitle1: "Contact",
    formTitle2: " and message",
    name: "First Name*",
    lastName: "Last Name*",
    company: "Company Name*",
    phone: "Telephone Number*",
    email: "Email Address*",
    message: "Message",
    opt0: "Tools, Products and Services*",
    opt1: "AiS - trip protection",
    opt2: "AIN - inteligent monitoring for fleet",
    opt3: "Protection included in policy",
    opt4: "General information",
    opt1Val: "AiS - Protección por viaje",
    opt2Val: "AIN - Protección de flotas",
    opt3Val: "Protección de póliza",
    opt4Val: "Información general",
    button: "Send",
    social: "Social media",
    success: "Message sent successfully",
    error: "Error, try again later",
    sending: "Sending...",
  },
  footer: {
    menu1Title: "Technological tools",
    menu1Subtitle1: "ELIAS",
    menu1Subtitle2: "Risk algorithm",
    menu1Subtitle3: "Follow/Protector",
    menu1Subtitle4: "RPA (Robotic Process Automation)",
    menu2Title: "Products and services",
    menu2Subtitle1: "AIS Secure Mobility",
    menu2Subtitle2: "AIN",
    menu3Title: "Company",
    menu3Subtitle1: "AI27 Community",
    menu3Subtitle2: "Newsletter",
    menu3Subtitle3: "Terms and conditions",
    menu3Subtitle4: "Privacy notices",
    menu3Subtitle5: "Ethics code",
    menu3Subtitle6: "Check out our quality and safety policy",
    menu4Title: "Contact Us",
    menu4Subtitle1: "contacto@ai27.com",
    menu4Subtitle2: "Commercial attention:",
    menu4Subtitle3: "5561223010",
    menu4Subtitle4: "5549099269",
    menu4Subtitle5: "5514516093",
    menu4Subtitle6: "5558990400",
    menu4Subtitle7: `Tecnoparque, eje 5 Nte 990,Santa Bárbara Azcapotzalco, 
		02230 Ciudad de México`,
  },
};

export default languaje;