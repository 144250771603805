const languaje = {
  header: {
    spa: "ESP",
    eng: "ING",
    home: "Inicio",
    tools: "Herramientas tecnológicas",
    products: "Productos y servicios",
    coverage: "Cobertura completa(seguros)",
    contact: "Contacto",
  },
  home: {
    carouselText1: "IA predicción de riesgos",
    carouselText2: "Tecnología en transporte",
    carouselText3: "Tecnología en logística",
    carouselText4: "Líder en seguridad",
    carouselText5: "Líder en prevención",
    icon1Text: "Años de trayectoria",
    icon2Text: "Líneas de transporte",
    icon3Text: "Clientes AAA",
  },
  tools: {
    title1: "Herramientas",
    title2: "tecnológicas",
    subtitle: `Desarrollamos herramientas tecnológicas  soportadas con IA, 
    para integrarnos a cualquier operación o sistema`,
    cart1Title: "ELIAS",
    cart1Subtitle: `Gemelo digital creado con tecnología de procesamiento para 
    monitoreo de flotas y comunicación con operadores.`,
    cart2Title: "Algoritmo de riesgo",
    cart2Subtitle: `Primer algoritmo predictivo del mercado, capaz de anticipar y 
		gestionar riesgos logísticos antes, durante y después de salir a ruta.`,
    cart3Title: "Follow/Protector",
    cart3Subtitle: `Aplicación móvil que proporciona información de ubicación en 
		tiempo real de embarques, visibilidad 24/7 de operaciones y botón 
		de pánico incluido.`,
    cart4Title: "RPA ",
    cart4Subtitle: `Desarrollamos nuestro propio RPA entrenado con IA que 
		permite conectarnos a cualquier sistema de monitoreo.`,
  },
  products: {
    title1: "Productos",
    title2: "y servicios",
    subtitle1: "¿Cuáles son nuestros productos y servicios?",
    subtitle2: "",
    cart1Title1: "AiS Secure",
    cart1Title2: "Mobility",
    cart1Button: "Iniciar Sesión >",
    cart1Content1: `Conozca nuestra plataforma tecnológica AIS Secure Mobility y 
		obtenga el control total de sus embarques, análisis de riesgo en tiempo real, 
		recomendaciones para disminuir el porcentaje de riesgo y reportes al finalizar 
		cada viaje.`,
    cart1Content2: `Alertamos los sucesos más relevantes y tomamos acción en 
		automático para protegerlo(a).`,
    cart1Content3: `Sistema de fácil implementación para cualquier operación 
		gracias a nuestra tecnología de RPA.`,
    cart1Content4: `Contrate protección acorde al volumen de sus viajes en tiempo 
		real.`,
    cart1Button1: "Proteja su operación",
    cart1Button2: "Comprar aquí",
    cart2Title: "AIN",
    cart2Content1: `Monitoreo inteligente para flotas en tiempo real 24/7, ofrece 
		visibilidad total por medio de Dashboard Power BI y control de riesgo para 
		sus flotas con Inteligencia Artificial.`,
    cart2Content2: `Sistema de fácil implementación e integración con su GPS 
		actual, diseñado para un modelo de flotas pesadas y ligeras.`,
    cart2Content3: `Proporcionamos alertamiento en tiempo real sobre riesgo, 
		velocidad, cansancio o distracción del operador.`,
    cart2Button1: "Proteja su flota",
    cart2Button2: "Contactar aquí",
  },
  coverage: {
    title1: "Nuestras",
    title2: "operaciones",
    cart1Title: "Capacidad de cobertura:",
    cart1Content: `Contamos con capacidad de cobertura Nacional e Internacional en 
		todo el continente de América. `,
    cart2Title: "Reducción de siniestralidad:",
    cart2Content: `Gracias a nuestra IA, solo el 0.04% de las operaciones de 
		nuestros clientes sufren pérdidas.`,
    cart3Title: "Capacidad predictiva:",
    cart3Content: `Monitoreamos 900 mil viajes mensuales. 2 mil millones de 
		datos recopilados al mes, para lograr 704 mil predicciones diarias.`,
    cart4Title: "Capacidad operativa:",
    cart4Content: `Hemos protegido 12 mil líneas  de transporte y 47 mil millones
		 de pesos en patrimonio y mercancía cada año. `,
  },
  customers: {
    cart1Title1: "Cobertura",
    cart1Title2: " completa",
    cart1Content: `AI27 puede ayudarle a obtener mejores condiciones en su póliza 
		de seguro de flota o carga a través de nuestros socios. Contacte a su agente 
		o broker, para solicitar cobertura con AI27.`,
    cart1Button1: "Contactar expertos",
    cart1Button2: "Contactar aquí",
    cart2Title1: "Nuestros",
    cart2Title2: " clientes",
    cart2Content: `Nuestros clientes confían en las soluciones tecnológicas y 
    productos que hemos desarrollado, lo que nos ha permitido construir una 
    sólida reputación en sectores clave como el abarrotero, farmacéutico, 
    retail, tecnológico, entre otros.`,
  },
  contact: {
    formTitle1: "Contacto",
    formTitle2: " y mensaje",
    name: "Nombre*",
    lastName: "Apellidos*",
    company: "Nombre de la Empresa*",
    phone: "Número Telefónico*",
    email: "Dirección de Correo*",
    message: "Mensaje",
    opt0: "Herramientas, Productos y Servicios*",
    opt1: "AiS - protección por viaje",
    opt2: "AIN - monitoreo inteligente para flotas",
    opt3: "Protección incluida en póliza",
    opt4: "Información general",
    opt1Val: "AiS - Protección por viaje",
    opt2Val: "AIN - Protección de flotas",
    opt3Val: "Protección de póliza",
    opt4Val: "Información general",
    button: "Enviar",
    social: "Redes sociales",
    success: "Mensaje enviado con éxito",
    error: "Error, intentalo mas tarde",
    sending: "Enviando...",
  },
  footer: {
    menu1Title: "Herramientas tecnologícas",
    menu1Subtitle1: "ELIAS",
    menu1Subtitle2: "Algoritmo de riesgo",
    menu1Subtitle3: "Follow/Protector",
    menu1Subtitle4: "RPA (Robotic Process Automation)",
    menu2Title: "Productos y servicios",
    menu2Subtitle1: "AIS Secure Mobility",
    menu2Subtitle2: "AIN",
    menu3Title: "Compañia",
    menu3Subtitle1: "Comunidad AI27",
    menu3Subtitle2: "Newsletter",
    menu3Subtitle3: "Términos y condiciones",
    menu3Subtitle4: "Avisos de privacidad",
    menu3Subtitle5: "Código de ética",
    menu3Subtitle6: "Consulta nuestra política de calidad y seguridad",
    menu4Title: "Contacto",
    menu4Subtitle1: "contacto@ai27.com",
    menu4Subtitle2: "Atención comercial:",
    menu4Subtitle3: "5561223010",
    menu4Subtitle4: "5549099269",
    menu4Subtitle5: "5514516093",
    menu4Subtitle6: "5558990400",
    menu4Subtitle7: `Tecnoparque, eje 5 Nte 990,Santa Bárbara Azcapotzalco, 
		02230 Ciudad de México`,
  },
};

export default languaje;